<template>
  <main class="flex-auto">
    <div class="px-4 py-14 sm:py-16 lg:py-20">
      <div class="prose prose-sm mx-auto">
        <h1 class="mt-3 mb-3">プライバシーポリシー</h1>
        <p>
          テラスペース株式会社（以下「当社」といいます。）は、当社がTerraSpace（以下「本サービス」といいます。）を提供するにあたり、ご利用される皆様（以下「利用者」といいます。）の個人に関する情報（以下「個人情報」といいます。）を取得します。
        </p>

        <h2 class="mt-3">第1条 （適用範囲）</h2>
        <p>
          本プライバシーポリシー（以下「本ポリシー」といいます。）は、当社が利用者から個人情報を取得、利用及び管理するときに適用されます。
        </p>

        <h2>第2条 （取得する情報）</h2>
        <p>当社は、利用者から以下の情報を取得します。</p>
        <p>(1) 氏名</p>
        <p>(2) 住所</p>
        <p>(3) 生年月日</p>
        <p>(4) 年齢</p>
        <p>(5) 連絡先</p>
        <p>(6) クレジットカード情報、銀行口座情報等の決済に関する情報</p>

        <h2 class="mt-3">第3条 （利用目的）</h2>
        <p>当社が個人情報を収集・利用する目的は、以下のとおりです。</p>
        <p>(1) 本サービスの提供・運営のため</p>
        <p>(2) 本サービスの運営上必要な事項の通知のため</p>
        <p>(3) 本サービスの会員であるお客様の管理のため</p>
        <p>(4) 本サービスに関する新機能、更新情報をお知らせするため</p>
        <p>(5) キャンペーン、懸賞企画、アンケートの実施のため</p>
        <p>(6) 本サービスの各種問合せ、アフターサービス対応のため</p>
        <p>(7) 本サービスその他のコンテンツの開発・改善のため</p>
        <p>(8) 当社が実施するサービス又は企画に関する連絡のため</p>
        <p>(9) 上記の利用目的に付随する目的</p>

        <h2 class="mt-3">第4条 （安全確保の措置）</h2>
        <p>
          当社は、収集した情報の漏えい、滅失又はき損の防止その他収集した情報の適切な管理のために必要な措置を講じます。当社が、安全管理のために講じた措置の概要は以下のとおりです。措置の具体的内容については、本ポリシーで定める窓口に対する利用者からの求めに応じて遅滞なく回答いたします。
        </p>
        <p>(1) 基本方針の策定、個人情報の取扱いに係る規律の整備</p>
        <p>(2) 個人情報の取扱責任者や報告連絡体制の整備</p>
        <p>(3) 定期的な研修の実施</p>
        <p>(4) 個人情報についての秘密保持に関する誓約を従業者から取得</p>
        <p>(5) 個人情報についての秘密保持に関する事項を就業規則に記載</p>
        <p>
          (6)
          責任者以外個人情報を容易に閲覧できないような措置（間仕切りやICカードによる入退室管理等も含む）の実施
        </p>
        <p>
          (7)
          個人情報を取り扱う機器等の盗難紛失等を防止するための措置（機器の書庫への保管、パスワードの設定等を含む）を実施
        </p>
        <p>
          (8)
          個人情報を取り扱うことのできる機器やアクセス権者を明確にし、個人情報への不要なアクセスを防止
        </p>
        <p>(9) メール等により取扱う際に、パスワードを設定</p>
        <p>(10) 個人情報を取り扱う機器等のOSを最新の状態に保持</p>
        <p>
          (11) 個人情報を取り扱う機器等にセキュリティ対策ソフトウェア等を導入
        </p>

        <h2 class="mt-3">第5条 （個人情報の第三者への提供）</h2>
        <p>
          1
          当社は、次に掲げる場合を除いて、あらかじめ利用者の同意を得ないで、取得した個人情報を第三者に提供することはありません。
        </p>
        <p>(1) 法令に基づく場合</p>
        <p>
          (2)
          人の生命、身体又は財産の保護のために必要がある場合であって、利用者の同意を得ることが困難であるとき。
        </p>
        <p>
          (3)
          公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、利用者の同意を得ることが困難であるとき。
        </p>
        <p>
          (4)
          国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。
        </p>
        <p>
          2
          前項の定めにかかわらず、次に掲げる場合には、当該個人情報の提供先は第三者に該当しないものとします。
        </p>
        <p>
          (1)
          当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って当該個人情報が提供される場合
        </p>
        <p>
          (2) 合併その他の事由による事業の承継に伴って個人情報が提供される場合
        </p>
        <h2 class="mt-3">第6条 （個人情報の共同利用）</h2>
        <p>
          当社は、特定の者との間で共同利用することを目的として個人情報を当該特定の者に提供することがあります。この場合、当社は、あらかじめ、共同して利用する個人情報の項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人情報の管理について責任を有する者の氏名又は名称を公表するものとします。
        </p>

        <h2>第7条 （本プライバシーポリシーの変更）</h2>
        <p>
          当社は、法令改正への対応の必要性及び事業上の必要性に応じて、本ポリシーを変更する場合があります。本ポリシーの変更を行った場合には、本ウェブサイト上に掲載します。
        </p>

        <h2>第8条 （開示、訂正等の手続）</h2>
        <p>
          1
          利用者は、本条に従って、当社に対し以下の求め又は請求を行うことができます。
        </p>
        <p>(1) 利用者から個人情報の利用目的の通知の求め</p>
        <p>(2) 利用者の個人情報の開示の請求</p>
        <p>
          (3)
          当社が保有する利用者の個人情報の内容が事実でない場合における、当該内容の訂正、追加又は削除の請求
        </p>
        <p>
          (4)
          利用者の個人情報が利用者に対して通知若しくは公表した利用目的の達成に必要な範囲を超えて取り扱われた場合又は当該個人情報が偽りその他不正の手段によって取得された場合における、当該個人情報の利用の停止又は消去の請求
        </p>
        <p>
          (5)
          利用者の個人情報が個人情報保護法第23条第1項又は第24条の規定に違反して第三者に提供されている場合における、当該個人情報の第三者への提供の停止の請求
        </p>
        <p>
          2
          前項の求め又は請求にあたっては、同項各号のうちいずれの請求か特定の上、本人確認のための書類（運転免許証、健康保険証、住民票の写し等）をご提出頂きます。
        </p>

        <h2 class="mt-3">第9条 （お問い合わせ）</h2>
        <p>
          当社の個人情報の取扱いに関するご相談や苦情等のお問い合わせについては、下記の窓口にご連絡ください。
        </p>
        <p>個人情報取扱事業者：テラスペース株式会社</p>
        <p>代表者名：代表取締役 北川貞大</p>
        <p>住所：京都府京都市左京区吉田本町36番地1</p>
        <p>京都大学国際科学イノベーション棟104</p>
        <p>Eメールアドレス： info@terraspace.jp</p>
      </div>
    </div>
  </main>
</template>
