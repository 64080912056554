import axios from "axios";
import { Auth } from "aws-amplify";
import Decedent, { DecedentJson } from "./Decedent";
import Temple, { TempleJson } from "./Temple";

export default abstract class Followable {
  id: string;
  username: string;
  selfie_image_url: string | null;

  get type(): string {
    return "";
  }

  get display_name(): string {
    return "";
  }

  get hasNew(): boolean {
    return false;
  }

  get has_memorial_menu_v(): boolean {
    return false;
  }

  get has_wish_menu_v(): boolean {
    return false;
  }

  constructor(id: string, username: string, selfie_image_url: string | null) {
    // console.log(username);
    this.id = id;
    this.username = username;
    this.selfie_image_url = selfie_image_url;
  }

  setRead() {
    // console.log("absolute");
  }
}
