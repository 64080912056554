import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import { MediaResource, MediaResourceJson } from "@/models/MediaResource";

interface CommentJson {
  timeline_id: string;
  timestamp: number;
  publisher_id: string;
  publisher_name: string;
  publisher_username: string;
  publisher_selfie_image_url: string;
  comment: string;
  media_urls: MediaResourceJson[];
  stamp_count: number;
  stamped: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface CommentSearchResult {
  comments: Comment[];
  last_evaluated_key: string | null;
}

export default class Comment {
  timeline_id: string;
  timestamp: number;
  publisher_id: string;
  publisher_name: string;
  publisher_username: string;
  publisher_selfie_image_url: string;
  comment: string;
  media_urls: MediaResource[];
  stamp_count: number;
  stamped: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  is_posting = false;

  get comment_v(): string {
    if (!this.comment) {
      return "";
    }

    return this.comment.replace(/\n/g, "<br />");
  }

  constructor(
    timeline_id: string,
    timestamp: number,
    publisher_id: string,
    publisher_name: string,
    publisher_username: string,
    publisher_selfie_image_url: string,
    comment: string,
    media_urls: MediaResource[],
    stamp_count: number,
    stamped: boolean,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.timeline_id = timeline_id;
    this.timestamp = timestamp;
    this.publisher_id = publisher_id;
    this.publisher_name = publisher_name;
    this.publisher_username = publisher_username;
    this.publisher_selfie_image_url = publisher_selfie_image_url;
    this.comment = comment;
    this.media_urls = media_urls;
    this.stamp_count = stamp_count;
    this.stamped = stamped;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(target_id: string, timestamp: number): Promise<Comment> {
    // console.log(`Comment.get(${target_id}, ${timestamp})`);
    const user = await Auth.currentAuthenticatedUser();

    // console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/comments/${target_id}_${timestamp}`,
      config
    );

    const json = response.data.data as CommentJson;

    const resources: MediaResource[] = [];

    if (json.media_urls) {
      json.media_urls.forEach((mediaJson) =>
        resources.push(MediaResource.fromJson(mediaJson))
      );
    }
    return new Comment(
      json.timeline_id,
      json.timestamp,
      json.publisher_id,
      json.publisher_name,
      json.publisher_username,
      json.publisher_selfie_image_url,
      json.comment,
      resources,
      json.stamp_count,
      json.stamped,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    timeline_id: string,
    customer_id: string,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<CommentSearchResult> {
    // console.log(`Comment.search(${timeline_id}, ${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        timeline_id,
        customer_id,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/comments`,
      config
    );

    const list: Comment[] = [];
    response.data.data.forEach((json: CommentJson) => {
      const resources: MediaResource[] = [];

      if (json.media_urls) {
        json.media_urls.forEach((mediaJson) =>
          resources.push(MediaResource.fromJson(mediaJson))
        );
      }

      list.push(
        new Comment(
          json.timeline_id,
          json.timestamp,
          json.publisher_id,
          json.publisher_name,
          json.publisher_username,
          json.publisher_selfie_image_url,
          json.comment,
          resources,
          json.stamp_count,
          json.stamped,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      comments: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    timeline_id: string,
    customer_id: string,
    comment: string,
    media_urls: MediaResource[]
  ): Promise<Comment> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/comments`,
      {
        timeline_id: `${timeline_id}`,
        customer_id: customer_id,
        comment: comment,
        media_urls: media_urls,
      },
      config
    );

    // console.log(response);

    const json = response.data.data as CommentJson;

    const resources: MediaResource[] = [];

    if (json.media_urls) {
      json.media_urls.forEach((mediaJson) =>
        resources.push(MediaResource.fromJson(mediaJson))
      );
    }
    return new Comment(
      json.timeline_id,
      json.timestamp,
      json.publisher_id,
      json.publisher_name,
      json.publisher_username,
      json.publisher_selfie_image_url,
      json.comment,
      resources,
      json.stamp_count,
      json.stamped,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async update(
    temple_id: string,
    timestamp: number,
    title: string,
    body: string,
    available_from: Date | null,
    available_to: Date | null
  ): Promise<Comment> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/information/${temple_id}_${timestamp}`,
      {
        temple_id: temple_id,
        timestamp: timestamp,
        title: title,
        body: body,
        available_from:
          available_from != null
            ? moment(available_from).format("YYYY-MM-DD")
            : null,
        available_to:
          available_to != null
            ? moment(available_to).format("YYYY-MM-DD")
            : null,
      },
      config
    );

    // console.log(response);

    const json = response.data as CommentJson;

    const resources: MediaResource[] = [];

    if (json.media_urls) {
      json.media_urls.forEach((mediaJson) =>
        resources.push(MediaResource.fromJson(mediaJson))
      );
    }
    return new Comment(
      json.timeline_id,
      json.timestamp,
      json.publisher_id,
      json.publisher_name,
      json.publisher_username,
      json.publisher_selfie_image_url,
      json.comment,
      resources,
      json.stamp_count,
      json.stamped,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async delete(
    timelinneId: string,
    timestamp: number
  ): Promise<Comment> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/comments/${timelinneId}@${timestamp}`,
      config
    );

    // console.log(response);

    const json = response.data as CommentJson;

    const resources: MediaResource[] = [];

    if (json.media_urls) {
      json.media_urls.forEach((mediaJson) =>
        resources.push(MediaResource.fromJson(mediaJson))
      );
    }
    return new Comment(
      json.timeline_id,
      json.timestamp,
      json.publisher_id,
      json.publisher_name,
      json.publisher_username,
      json.publisher_selfie_image_url,
      json.comment,
      resources,
      json.stamp_count,
      json.stamped,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async report(
    timelinneId: string,
    timestamp: number,
    customerId: string,
  ): Promise<Comment> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const params = {
      comment_id: `${timelinneId}@${timestamp}`,
      customer_id: customerId,
      type: 'report',
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/comments/${timelinneId}@${timestamp}`,
      params,
      config
    );

    // console.log(response);

    const json = response.data as CommentJson;

    const resources: MediaResource[] = [];

    if (json.media_urls) {
      json.media_urls.forEach((mediaJson) =>
        resources.push(MediaResource.fromJson(mediaJson))
      );
    }
    return new Comment(
      json.timeline_id,
      json.timestamp,
      json.publisher_id,
      json.publisher_name,
      json.publisher_username,
      json.publisher_selfie_image_url,
      json.comment,
      resources,
      json.stamp_count,
      json.stamped,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async postStamp(
    commentId: string,
    customerId: string,
    type: string
  ): Promise<boolean> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/commentstamps`,
      {
        comment_id: commentId,
        customer_id: customerId,
        type: type,
      },
      config
    );

    // console.log(response);

    return response.data.code == "0";
  }

  static async deleteStamp(
    timlineId: string,
    timestamp: number,
    customerId: string
  ): Promise<boolean> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/commentstamps/${timlineId}_${timestamp}@${customerId}`,
      config
    );

    // console.log(response);

    return response.data.code == "0";
  }

  static async postCommentStamp(
    comment_id: string,
    customer_id: string,
    type: string
  ): Promise<boolean> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/commentstamps`,
      {
        comment_id,
        customer_id,
        type: type,
      },
      config
    );

    // console.log(response);

    return response.data.code == "0";
  }

  static async deleteCommentStamp(
    comment_id: string,
    customer_id: string
  ): Promise<boolean> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/commentstamps/${comment_id}@${customer_id}`,
      config
    );

    // console.log(response);

    return response.data.code == "0";
  }
}
