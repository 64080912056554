import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";

interface MenuJson {
  id: string;
  menu_type: string;
  name: string;
  main_image_url: string | null;
  description: string;
  temple_prices: any;
  available_from: string | null;
  available_to: string | null;
  accept_text: boolean;
  accept_photo: boolean;
  accept_video: boolean;
  accept_bone: boolean;
  accept_syakyo: boolean;
  upper_limit_file_size: number | null;
  upper_limit_file_count: number | null;
  attached_byobu: boolean;
  attached_memorial_goods: boolean;
  redirect_url: string | null;
  display_order: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface MenuSearchResult {
  menus: Menu[];
  last_evaluated_key: string | null;
}

export default class Menu {
  id: string;
  menu_type: string;
  name: string;
  main_image_url: string | null;
  description: string;
  temple_prices: any;
  available_from: Date | null;
  available_to: Date | null;
  accept_text: boolean;
  accept_photo: boolean;
  accept_video: boolean;
  accept_bone: boolean;
  accept_syakyo: boolean;
  upper_limit_file_size: number | null;
  upper_limit_file_count: number | null;
  attached_byobu: boolean;
  attached_memorial_goods: boolean;
  redirect_url: string | null;
  display_order: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  is_selected: boolean;

  constructor(
    id: string,
    menu_type: string,
    name: string,
    main_image_url: string | null,
    description: string,
    // wholesale_price: number,
    // default_list_price: number,
    temple_prices: any,
    available_from: Date | null,
    available_to: Date | null,
    accept_text: boolean,
    accept_photo: boolean,
    accept_video: boolean,
    accept_bone: boolean,
    accept_syakyo: boolean,
    upper_limit_file_size: number | null,
    upper_limit_file_count: number | null,
    attached_byobu: boolean,
    attached_memorial_goods: boolean,
    redirect_url: string | null,
    display_order: number,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.menu_type = menu_type;
    this.name = name;
    this.main_image_url = main_image_url;
    this.description = description;
    // this.wholesale_price = wholesale_price;
    // this.default_list_price = default_list_price;
    this.temple_prices = temple_prices;
    this.available_from = available_from;
    this.available_to = available_to;
    this.accept_text = accept_text;
    this.accept_photo = accept_photo;
    this.accept_video = accept_video;
    this.accept_bone = accept_bone;
    this.accept_syakyo = accept_syakyo;
    this.upper_limit_file_size = upper_limit_file_size;
    this.upper_limit_file_count = upper_limit_file_count;
    this.attached_byobu = attached_byobu;
    this.attached_memorial_goods = attached_memorial_goods;
    this.redirect_url = redirect_url;
    this.display_order = display_order;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;

    this.is_selected = false;
  }

  static async get(id: string): Promise<Menu> {
    // console.log(`Menu.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    // console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/menus/${id}`,
      config
    );

    const json = response.data.data as MenuJson;

    return new Menu(
      json.id,
      json.menu_type,
      json.name,
      json.main_image_url,
      json.description,
      json.temple_prices,
      json.available_from ? new Date(json.available_from) : null,
      json.available_to ? new Date(json.available_to) : null,
      json.accept_text,
      json.accept_photo,
      json.accept_video,
      json.accept_bone,
      json.accept_syakyo,
      json.upper_limit_file_size,
      json.upper_limit_file_count,
      json.attached_byobu,
      json.attached_memorial_goods,
      json.redirect_url,
      json.display_order,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    keyword: string | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<MenuSearchResult> {
    // console.log(`Menu.search(${keyword}, ${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        keyword,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/menus`,
      config
    );

    const list: Menu[] = [];
    response.data.data.forEach((json: MenuJson) => {
      list.push(
        new Menu(
          json.id,
          json.menu_type,
          json.name,
          json.main_image_url,
          json.description,
          json.temple_prices,
          json.available_from ? new Date(json.available_from) : null,
          json.available_to ? new Date(json.available_to) : null,
          json.accept_text,
          json.accept_photo,
          json.accept_video,
          json.accept_bone,
          json.accept_syakyo,
          json.upper_limit_file_size,
          json.upper_limit_file_count,
          json.attached_byobu,
          json.attached_memorial_goods,
          json.redirect_url,
          json.display_order,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      menus: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async searchByTemple(id: string): Promise<MenuSearchResult> {
    // console.log(`Menu.searchByTemple(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        temple_id: id
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/menus`,
      config
    );

    const list: Menu[] = [];
    response.data.data.forEach((json: MenuJson) => {
      list.push(
        new Menu(
          json.id,
          json.menu_type,
          json.name,
          json.main_image_url,
          json.description,
          json.temple_prices,
          json.available_from ? new Date(json.available_from) : null,
          json.available_to ? new Date(json.available_to) : null,
          json.accept_text,
          json.accept_photo,
          json.accept_video,
          json.accept_bone,
          json.accept_syakyo,
          json.upper_limit_file_size,
          json.upper_limit_file_count,
          json.attached_byobu,
          json.attached_memorial_goods,
          json.redirect_url,
          json.display_order,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    list.sort((l1, l2) => {
      return l1.display_order - l2.display_order;
    });

    return {
      menus: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }
}
