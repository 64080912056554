import { createApp } from "vue";
import App from "@/App.vue";
import router from "./router";
import store from "./store";
import "@/plugins/amplify";
import "@/plugins/yup";
import "@/assets/index.css";

import FontAwesomeIcon from "@/plugins/font-awesome";

import Vue3VideoPlayer from '@cloudgeek/vue3-video-player';
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css';

import TextClamp from 'vue3-text-clamp';

createApp(App)
  .use(store) //
  .use(router) //
  .use(Vue3VideoPlayer, { lang: "jp" }) //
  .use(TextClamp) //
  .component("fa", FontAwesomeIcon)
  .mount("#app");
