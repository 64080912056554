<template>
  <main class="flex-auto">
    <div>
      <h1>【特定商取引法に基づく表記】</h1>
      <table
        bgcolor="#999999"
        border="0"
        cellpadding="9"
        cellspacing="1"
        style="font-size: 13px; text-align: left"
      >
        <tr>
          <th bgcolor="#EEEEEE">販売事業者</th>
          <td bgcolor="#FFFFFF">テラスペース株式会社</td>
        </tr>
        <tr>
          <th bgcolor="#EEEEEE">運営統括責任者</th>
          <td bgcolor="#FFFFFF">北川貞大</td>
        </tr>
        <tr>
          <th bgcolor="#EEEEEE">所在地</th>
          <td bgcolor="#FFFFFF">
            京都市左京区吉田本町36番地1京都大学国際科学イノベーション棟104
          </td>
        </tr>
        <tr>
          <th bgcolor="#EEEEEE">メールアドレス</th>
          <td bgcolor="#FFFFFF">info@terraspace.jp</td>
        </tr>
        <tr>
          <th bgcolor="#EEEEEE">お支払い方法</th>
          <td bgcolor="#FFFFFF">クレジットカード</td>
        </tr>
        <tr>
          <th bgcolor="#EEEEEE">販売数量</th>
          <td bgcolor="#FFFFFF">1式から</td>
        </tr>
        <tr>
          <th bgcolor="#EEEEEE">商品引渡し時期</th>
          <td bgcolor="#FFFFFF">
            指定日が無ければ入金確認後7営業日以内で発送致します。
          </td>
        </tr>
        <tr>
          <th bgcolor="#EEEEEE">商品引渡し方法</th>
          <td bgcolor="#FFFFFF">当方にて手配後、運送会社による配送</td>
        </tr>
        <tr>
          <th bgcolor="#EEEEEE">返品・不良品について</th>
          <td bgcolor="#FFFFFF">
            「不良品・当社の商品の間違い」の場合は当社が負担いたします。<br />
            配送途中の破損などの事故がございましたら、弊社までご連絡下さい。<br />
            送料・手数料ともに弊社負担で早急に新品をご送付致します。<br /><br />
            【返品対象】<br />「不良品・当社の商品の間違い」の場合<br /><br />
            【返品時期】<br />ご購入後7日以内にご連絡があった場合に返金可能となります。<br /><br />
            【返品方法】<br />メールにて返金要請してください。クレジットカード決済の場合、7日以内に決済のキャンセル処理を行います。<br /><br />
          </td>
        </tr>
        <tr>
          <th bgcolor="#EEEEEE">表現、及び商品に関する注意書き</th>
          <td bgcolor="#FFFFFF">
            本商品に示された表現や再現性には個人差があり、<br />必ずしも利益や効果を保証したものではございません。
          </td>
        </tr>
      </table>
    </div>
  </main>
</template>
