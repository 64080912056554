import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import { MediaResource, MediaResourceJson } from "@/models/MediaResource";

interface StampJson {
  timeline_id: string;
  customer_id: string;
  type: string;
  publisher_id: string;
  publisher_name: string;
  publisher_username: string;
  publisher_selfie_image_url: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export default class Stamp {
  timeline_id: string;
  customer_id: string;
  type: string;
  publisher_id: string;
  publisher_name: string;
  publisher_username: string;
  publisher_selfie_image_url: string | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor(
    timeline_id: string,
    customer_id: string,
    type: string,
    publisher_id: string,
    publisher_name: string,
    publisher_username: string,
    publisher_selfie_image_url: string | null,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.timeline_id = timeline_id;
    this.customer_id = customer_id;
    this.type = type;
    this.publisher_id = publisher_id;
    this.publisher_name = publisher_name;
    this.publisher_username = publisher_username;
    this.publisher_selfie_image_url = publisher_selfie_image_url;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(timelineId: string, customerId: string): Promise<Stamp | null> {
    // console.log(`Stamp.search()`);
    const user = await Auth.currentAuthenticatedUser();

    // console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/stamps/${timelineId}@${customerId}`,
      config
    );

    // console.log(response);

    const json = response.data.data as StampJson | null;
    if (!json) {
      return null;
    }

    return new Stamp(
      json.timeline_id,
      json.customer_id,
      json.type,
      json.publisher_id,
      json.publisher_name,
      json.publisher_username,
      json.publisher_selfie_image_url,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(timelineId: string): Promise<Stamp[]> {
    // console.log(`Stamp.search()`);
    const user = await Auth.currentAuthenticatedUser();

    // console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        timeline_id: timelineId,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/stamps`,
      config
    );

    // console.log(response);

    const list: Stamp[] = [];

    response.data.data.forEach((json: any) => {
      list.push(
        new Stamp(
          json.timeline_id,
          json.customer_id,
          json.type,
          json.publisher_id,
          json.publisher_name,
          json.publisher_username,
          json.publisher_selfie_image_url,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return list;
  }
}
