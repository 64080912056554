import moment from "moment";

export const formatSnsTime = (datetime: Date | null | undefined) => {
  if (datetime == null) {
    return "";
  }

  const dt = moment(datetime);
  const now = moment();

  const diff = now.diff(dt);

  if (diff <= 60 * 1000) {
    return "今";
  }

  if (diff <= 60 * 60 * 1000) {
    const min = (diff / (60 * 1000)).toFixed(0);
    return `${min}分前`;
  }
  if (diff <= 24 * 60 * 60 * 1000) {
    const hour = (diff / (60 * 60 * 1000)).toFixed(0);
    return `${hour}時間前`;
  }
  if (diff <= 7 * 24 * 60 * 60 * 1000) {
    const day = (diff / (24 * 60 * 60 * 1000)).toFixed(0);
    return `${day}日前`;
  }

  if (diff <= 365 * 7 * 24 * 60 * 60 * 1000) {
    return dt.format("M月D日");
  }

  return dt.format("YYYY年M月D日");
};

export const formatStampTypeTitle = (type: string) => {
  if (type == null) {
    return "";
  }

  if (type == "pray1") {
    return "お参りしました";
  }

  if (type == "flower1") {
    return "お花をお供えしました";
  }

  if (type == "incense1") {
    return "お線香をあげましました";
  }

  if (type == "pray2") {
    return "お参りしました";
  }

  if (type == "pray3") {
    return "お祈りしました";
  }

  if (type == "pray4") {
    return "お参りしました";
  }

  if (type == "pray5") {
    return "お祈りしました";
  }

  return "";
};

export const formatRelationshipLabel = (relationship: string | null) => {
  if (!relationship) {
    return "";
  }

  if (relationship == "business") {
    return "仕事・同僚";
  }

  if (relationship == "hobby") {
    return "趣味";
  }

  if (relationship == "school") {
    return "学校・学友";
  }

  if (relationship == "neighborhood") {
    return "ご近所";
  }

  if (relationship == "family") {
    return "親族";
  }

  return "その他";
};
