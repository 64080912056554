import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import { MediaResource, MediaResourceJson } from "@/models/MediaResource";
import Followable from "./Followable";
import Temple, { TempleJson } from "./Temple";
import Decedent, { DecedentJson } from "./Decedent";

export interface CustomerJson {
  id: string;
  username: string;
  family_name: string | null;
  family_name_kana: string | null;
  given_name: string | null;
  given_name_kana: string | null;
  selfie_image_url: string | null;
  email: string | null;
  decedent_ids: string[];
  stripe_id: string;
  postcode: string | null;
  prefecture: string | null;
  city: string | null;
  address: string | null;
  building: string | null;
  telephone: string | null;
  follow_count: number;
  follower_count: number;
  post_count: number;
  relationship: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export default class Customer {
  id: string;
  username: string;
  family_name: string | null;
  family_name_kana: string | null;
  given_name: string | null;
  given_name_kana: string | null;
  selfie_image_url: string | null;
  email: string | null;
  decedent_ids: string[];
  stripe_id: string;
  postcode: string | null;
  prefecture: string | null;
  city: string | null;
  address: string | null;
  building: string | null;
  telephone: string | null;
  follow_count: number;
  follower_count: number;
  post_count: number;
  relationship: string | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  get display_name(): string {
    return `${this.family_name} ${this.given_name}`;
  }

  constructor(
    id: string,
    username: string,
    family_name: string | null,
    family_name_kana: string | null,
    given_name: string | null,
    given_name_kana: string | null,
    selfie_image_url: string | null,
    email: string | null,
    decedent_ids: string[],
    stripe_id: string,
    postcode: string | null,
    prefecture: string | null,
    city: string | null,
    address: string | null,
    building: string | null,
    telephone: string | null,
    follow_count: number,
    follower_count: number,
    post_count: number,
    relationship: string | null,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.username = username;
    this.family_name = family_name;
    this.family_name_kana = family_name_kana;
    this.given_name = given_name;
    this.given_name_kana = given_name_kana;
    this.selfie_image_url = selfie_image_url;
    this.email = email;
    this.decedent_ids = decedent_ids;
    this.stripe_id = stripe_id;
    this.postcode = postcode;
    this.prefecture = prefecture;
    this.city = city;
    this.address = address;
    this.building = building;
    this.telephone = telephone;
    this.follow_count = follow_count;
    this.follower_count = follower_count;
    this.post_count = post_count;
    this.relationship = relationship;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(id: string): Promise<Customer> {
    // console.log(`Customer.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    // console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/customers/${id}`,
      config
    );

    // console.log(response);

    const json = response.data.data as CustomerJson;

    return new Customer(
      json.id,
      json.username,
      json.family_name,
      json.family_name_kana,
      json.given_name,
      json.given_name_kana,
      json.selfie_image_url,
      json.email,
      json.decedent_ids,
      json.stripe_id,
      json.postcode,
      json.prefecture,
      json.city,
      json.address,
      json.building,
      json.telephone,
      json.follow_count,
      json.follower_count,
      json.post_count,
      json.relationship,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async update(
    customer_id: string,
    username: string,
    selfie_image_url: string | null,
    email: string,
    family_name: string,
    given_name: string,
    family_name_kana: string | null,
    given_name_kana: string | null,
    postcode: string | null,
    prefecture: string | null,
    city: string | null,
    address: string | null,
    building: string | null,
    telephone: string | null
  ): Promise<Customer> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/customers/${customer_id}`,
      {
        username: username,
        selfie_image_url: selfie_image_url,
        email: email,
        family_name: family_name,
        given_name: given_name,
        family_name_kana: family_name_kana,
        given_name_kana: given_name_kana,
        postcode: postcode,
        prefecture: prefecture,
        city: city,
        address: address,
        building: building,
        telephone: telephone,
      },
      config
    );

    // console.log(response);

    const json = response.data.data as CustomerJson;

    return new Customer(
      json.id,
      json.username,
      json.family_name,
      json.family_name_kana,
      json.given_name,
      json.given_name_kana,
      json.selfie_image_url,
      json.email,
      json.decedent_ids,
      json.stripe_id,
      json.postcode,
      json.prefecture,
      json.city,
      json.address,
      json.building,
      json.telephone,
      json.follow_count,
      json.follower_count,
      json.post_count,
      json.relationship,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async isFollow(
    customerId: string,
    targetId: string
  ): Promise<boolean> {
    // console.log(`Followable.isFollow(${customerId},${targetId})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/followers/${customerId}_${targetId}`,
      config
    );

    // console.log(response);

    return response.data.code == "0";
  }

  static async getFollowers(id: string): Promise<Followable[]> {
    // console.log(`Followable.getFollowers(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        follower_id: id,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/followers`,
      config
    );

    // console.log(response);

    const list: Followable[] = [];

    response.data.data.forEach((json: any) => {
      // console.log(json);

      if (json.type == "temple") {
        const templejson = json as TempleJson;
        list.push(
          new Temple(
            templejson.id,
            templejson.username,
            templejson.name,
            templejson.kana,
            templejson.selfie_image_url,
            templejson.main_image_url,
            templejson.has_wish_menu,
            templejson.has_memorial_menu,
            templejson.postcode,
            templejson.prefecture,
            templejson.city,
            templejson.address,
            templejson.building,
            templejson.telephone,
            templejson.email,
            templejson.website,
            templejson.bank_code,
            templejson.bank_name,
            templejson.branch_code,
            templejson.branch_name,
            templejson.account_type,
            templejson.account_no,
            templejson.account_name,
            templejson.follower_count,
            templejson.post_count,
            templejson.praying_stamp1_count,
            templejson.praying_stamp2_count,
            templejson.praying_stamp3_count,
            templejson.praying_stamp4_count,
            templejson.praying_stamp5_count,
            templejson.praying_stamp6_count,
            templejson.praying_stamp7_count,
            templejson.timeline_published_time,
            templejson.read_time,
            new Date(templejson.created_at),
            new Date(templejson.updated_at),
            templejson.deleted_at ? new Date(templejson.deleted_at) : null
          )
        );
      } else if (json.type == "decedent") {
        const decedentjson = json as DecedentJson;
        list.push(
          new Decedent(
            decedentjson.id,
            decedentjson.username,
            decedentjson.is_celebrity,
            decedentjson.selfie_image_url,
            decedentjson.name,
            decedentjson.family_name,
            decedentjson.given_name,
            decedentjson.family_name_kana,
            decedentjson.given_name_kana,
            decedentjson.nickname,
            decedentjson.buddist_name,
            decedentjson.buddist_name_kana,
            decedentjson.date_of_birth,
            decedentjson.date_of_dead,
            decedentjson.age,
            decedentjson.age_of_dead_type,
            decedentjson.chief_mourner,
            decedentjson.introduce,
            decedentjson.birthplace,
            decedentjson.hobby,
            decedentjson.favorite_food,
            decedentjson.school,
            decedentjson.job,
            decedentjson.conferment,
            decedentjson.relationships,
            decedentjson.custom_praying_image_url,
            decedentjson.scope,
            decedentjson.is_accept_comment,
            decedentjson.follower_count,
            decedentjson.post_count,
            decedentjson.praying_stamp1_count,
            decedentjson.praying_stamp2_count,
            decedentjson.praying_stamp3_count,
            decedentjson.praying_stamp4_count,
            decedentjson.praying_stamp5_count,
            decedentjson.praying_stamp6_count,
            decedentjson.praying_stamp7_count,
            decedentjson.timeline_published_time,
            decedentjson.read_time,
            new Date(decedentjson.created_at),
            new Date(decedentjson.updated_at),
            decedentjson.deleted_at ? new Date(decedentjson.deleted_at) : null
          )
        );
      }
    });

    return list;
  }

  static async getFollowees(id: string): Promise<Followable[]> {
    // console.log(`Followable.getFollowees(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        followee_id: id,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/followers`,
      config
    );

    // console.log(response);

    const list: Followable[] = [];

    response.data.data.forEach((json: any) => {
      // console.log(json);

      if (json.type == "temp") {
        const templejson = json as TempleJson;
        list.push(
          new Temple(
            templejson.id,
            templejson.username,
            templejson.name,
            templejson.kana,
            templejson.selfie_image_url,
            templejson.main_image_url,
            templejson.has_wish_menu,
            templejson.has_memorial_menu,
            templejson.postcode,
            templejson.prefecture,
            templejson.city,
            templejson.address,
            templejson.building,
            templejson.telephone,
            templejson.email,
            templejson.website,
            templejson.bank_code,
            templejson.bank_name,
            templejson.branch_code,
            templejson.branch_name,
            templejson.account_type,
            templejson.account_no,
            templejson.account_name,
            templejson.follower_count,
            templejson.post_count,
            templejson.praying_stamp1_count,
            templejson.praying_stamp2_count,
            templejson.praying_stamp3_count,
            templejson.praying_stamp4_count,
            templejson.praying_stamp5_count,
            templejson.praying_stamp6_count,
            templejson.praying_stamp7_count,
            templejson.timeline_published_time,
            templejson.read_time,
            new Date(templejson.created_at),
            new Date(templejson.updated_at),
            templejson.deleted_at ? new Date(templejson.deleted_at) : null
          )
        );
      } else if (json.type == "decedent") {
        const decedentjson = json as DecedentJson;
        list.push(
          new Decedent(
            decedentjson.id,
            decedentjson.username,
            decedentjson.is_celebrity,
            decedentjson.selfie_image_url,
            decedentjson.name,
            decedentjson.family_name,
            decedentjson.given_name,
            decedentjson.family_name_kana,
            decedentjson.given_name_kana,
            decedentjson.nickname,
            decedentjson.buddist_name,
            decedentjson.buddist_name_kana,
            decedentjson.date_of_birth,
            decedentjson.date_of_dead,
            decedentjson.age,
            decedentjson.age_of_dead_type,
            decedentjson.chief_mourner,
            decedentjson.introduce,
            decedentjson.birthplace,
            decedentjson.hobby,
            decedentjson.favorite_food,
            decedentjson.school,
            decedentjson.job,
            decedentjson.conferment,
            decedentjson.relationships,
            decedentjson.custom_praying_image_url,
            decedentjson.scope,
            decedentjson.is_accept_comment,
            decedentjson.follower_count,
            decedentjson.post_count,
            decedentjson.praying_stamp1_count,
            decedentjson.praying_stamp2_count,
            decedentjson.praying_stamp3_count,
            decedentjson.praying_stamp4_count,
            decedentjson.praying_stamp5_count,
            decedentjson.praying_stamp6_count,
            decedentjson.praying_stamp7_count,
            decedentjson.timeline_published_time,
            decedentjson.read_time,
            new Date(decedentjson.created_at),
            new Date(decedentjson.updated_at),
            decedentjson.deleted_at ? new Date(decedentjson.deleted_at) : null
          )
        );
      }
    });

    return list;
  }

  static async follow(
    type: string,
    customerId: string,
    targetId: string,
    relationship: string | undefined
  ): Promise<boolean> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const params: any = {
      type: type,
      follower_id: customerId,
      followee_id: targetId,
    };

    if (relationship) {
      params["relationship"] = relationship;
    }

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/followers`,
      params,
      config
    );

    return response.data.code == "0";
  }

  static async unfollow(
    customerId: string,
    targetId: string
  ): Promise<boolean> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/followers/${customerId}_${targetId}`,
      config
    );

    return response.data.code == "0";
  }
}
